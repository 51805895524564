import { IStandings } from '@/models/standings';

export const displayClubNameWithMark = (club: IStandings, year: string) => {
	const yearMappings: Record<string, Record<string, string>> = {
		'2024/2025': {
			VIT: '*',
			DBO: '+',
			VOL: '+',
			ADO: '+',
		},
		'2023/2024': {
			GRO: '++',
			WIL: '+',
			RJC: '+',
		},
		'2022/2023': {
			HER: '++',
			ZWO: '+',
			ALM: '+',
		},
		'2021/2022': {
			EMM: '++',
			ADO: '+',
			EXC: '+',
		},
		'2020/2021': {
			CAM: '+++',
			GRA: '+',
		},
		'2019/2020': {
			NAC: '+',
			GRA: '+',
			VOL: '+',
		},
		'2018/2019': {
			TWE: '+',
			ALM: '+',
			SPA: '+',
			DBO: '+',
		},
		'2017/2018': {
			AJX: '+',
			FOR: '++',
			DOR: '+',
		},
		'2016/2017': {
			PSV: '+',
			VVV: '+',
			AJX: '+',
			CAM: '+',
		},
		'2015/2016': {
			VOL: '+',
			SPA: '+',
			EHV: '+',
			ALM: '+',
		},
	};

	const yearConfig = yearMappings[year];
	if (yearConfig && yearConfig[club.contestantCode]) {
		return `${club.contestantClubName} ${yearConfig[club.contestantCode]}`;
	}

	return club.contestantClubName;
};
