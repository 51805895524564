import React, { useMemo } from 'react';
import Link from 'next/link';

import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableHeadRow } from './table';
import { IStandings } from '../models/standings';
import { displayClubNameWithMark } from '@/utils/showClubNamePeriodicTitle';

interface IProps {
	clubs: IStandings[];
	legend?: boolean;
	snug?: boolean;
	invertedHeaderColors?: boolean;
	className?: string;
	displayPeriodTitle?: boolean;
	isHistoric?: boolean;
	showCorornaLegend?: boolean;
	year?: string;
	startIndex?: number;
}

export type PromotionTeamsType = { [key: string]: boolean | undefined };

const MAX_PROMOTION_TEAMS = 8;

const getLegendClassName = (club: IStandings, promotionTeams: PromotionTeamsType) => {
	switch (promotionTeams[club.contestantId]) {
		case true:
			return 'legend-blue';
		case false:
			return 'legend-yellow';
		default:
			return undefined;
	}
};

function getClassNameForRankStatus(rankStatus: string | undefined): string {
	switch (rankStatus) {
		case 'green':
			return 'legend-blue';
		case 'red':
			return 'legend-yellow';
		default:
			return '';
	}
}

const resultSplitter = (lastSix: string) => {
	const translationMap: { [key: string]: string } = {
		W: 'W',
		D: 'G',
		L: 'V',
	};
	const lettersArray = lastSix?.split('');
	const translatedLettersArray = lettersArray?.map((letter) => {
		return translationMap[letter] || letter;
	});

	return (
		<div>
			{translatedLettersArray?.map((letter, i) => (
				<span key={i} className={`result-${letter}`}>
					{letter}
				</span>
			))}
		</div>
	);
};

export default function StandingsTable({
	clubs,
	legend,
	snug,
	invertedHeaderColors,
	className,
	displayPeriodTitle,
	isHistoric,
	showCorornaLegend,
	year,
	startIndex = 0,
}: IProps) {
	// const promotionTeams = useMemo(() => {
	// 	let teams = clubs.filter((club) => !club.contestantName.includes('Jong'));

	// 	if (year === '2016/2017') {
	// 		teams = teams.filter(
	// 			(club) => !club.contestantName.includes('Eindhoven') && !club.contestantName.includes('Graaf'),
	// 		);
	// 	}

	// 	if (year === '2017/2018') {
	// 		teams = teams.filter(
	// 			(club) => !club.contestantName.includes('Eindhoven') && !club.contestantName.includes('Den Bosch'),
	// 		);
	// 	}

	// 	return teams
	// 		.slice(0, !isHistoric ? MAX_ITEMS_WITH_LEGEND : 9)
	// 		.reduce(
	// 			(acc, club, i) => ({ ...acc, [club.contestantId]: i < (isHistoric ? 1 : 2) }),
	// 			{} as PromotionTeamsType,
	// 		);
	// }, [clubs, year, isHistoric]);

	const periodChampions: string[] = ['eh54mfvjtrttgsg1wbjnvxcqz'];

	const promotionTeams = useMemo(() => {
		// Filter out Jong teams that cannot promote
		let eligibleTeams = clubs.filter((club) => !club.contestantName.includes('Jong'));

		// Handle year-specific exclusions
		if (year === '2016/2017') {
			eligibleTeams = eligibleTeams.filter(
				(club) => !club.contestantName.includes('Eindhoven') && !club.contestantName.includes('Graaf'),
			);
		}

		if (year === '2017/2018') {
			eligibleTeams = eligibleTeams.filter(
				(club) => !club.contestantName.includes('Eindhoven') && !club.contestantName.includes('Den Bosch'),
			);
		}

		// Identify period champions that are outside the top 8
		const periodChampionTeams = clubs.filter((club) => periodChampions.includes(club.contestantId));
		const periodChampionsOutsideTop8 = periodChampionTeams.filter(
			(club) => !eligibleTeams.slice(0, 8).some((team) => team.contestantId === club.contestantId),
		);

		// Allocate promotion spots
		let promotionMap: PromotionTeamsType = {};

		// First 2 teams get direct promotion (blue border)
		eligibleTeams.slice(0, 2).forEach((club) => {
			promotionMap[club.contestantId] = true;
		});

		// Next 6 teams normally get playoff spots (yellow border)
		let playoffTeams = eligibleTeams.slice(2, 8);

		// Adjust for period champions outside the top 8
		periodChampionsOutsideTop8.forEach((champion) => {
			// Remove the last team from the original playoff selection
			playoffTeams.pop();
			// Add the period champion
			playoffTeams.push(champion);
		});

		// Mark the remaining playoff teams (yellow border)
		playoffTeams.forEach((club) => {
			promotionMap[club.contestantId] = false;
		});

		return promotionMap;
	}, [clubs, year]);

	return (
		<div className="overflow-x-auto">
			<Table className={`table-auto overflow-scroll w-full ${className || ''}`} snug={snug}>
				<TableHead>
					<TableHeadRow className={invertedHeaderColors ? 'text-white' : 'text-black-500'}>
						<TableHeadCell className="w-1">
							<span className="sr-only">Legenda</span>
						</TableHeadCell>
						<TableHeadCell className="text-center w-3">#</TableHeadCell>
						<TableHeadCell
							className={
								snug
									? 'font-bold hidden md:table-cell lg:table-cell min-[1280px]:hidden min-[1536px]:table-cell'
									: 'font-bold hidden lg:table-cell'
							}
						></TableHeadCell>
						<TableHeadCell className="text-left">Club</TableHeadCell>
						<TableHeadCell>GS</TableHeadCell>
						<TableHeadCell className="w-[80px] md:w-auto">W / G / V</TableHeadCell>
						<TableHeadCell>P</TableHeadCell>
						<TableHeadCell className={snug ? 'inline-block w-[70px]' : ''}>DV / DT</TableHeadCell>
						<TableHeadCell>DS</TableHeadCell>
						<TableHeadCell className="hidden md:table-cell">Vorm</TableHeadCell>
					</TableHeadRow>
				</TableHead>
				<TableBody className="bg-white">
					{clubs.map((club, i) => (
						<tr key={club.contestantId} className="even:bg-gray-50">
							<>
								{legend ? (
									<TableCell className="w-2">
										<div className={getLegendClassName(club, promotionTeams)} />
									</TableCell>
								) : (
									<TableCell className="w-2">
										<div className={getClassNameForRankStatus(club.rankStatus)} />
									</TableCell>
								)}
							</>
							<TableCell className="text-center w-3">{startIndex + (i + 1)}</TableCell>
							<TableCell nowrap className="text-center min-w-[2.75rem]">
								{club.contestantLogo && (
									<Link href={`/teams/${club.contestantClubSlug}`}>
										<img
											src={club.contestantLogo}
											alt={club.contestantClubName}
											className={
												snug
													? 'inline-block w-6 h-6'
													: 'inline-block sm:w-10 sm:h-10 aspect-square object-contain'
											}
										/>
									</Link>
								)}
							</TableCell>
							<TableCell
								nowrap
								className={
									snug
										? 'font-bold hidden md:table-cell lg:table-cell min-[1280px]:hidden min-[1536px]:table-cell'
										: 'font-bold hidden lg:table-cell'
								}
							>
								<Link href={`/teams/${club.contestantClubSlug}`}>
									{displayClubNameWithMark(club, year ?? '2024/2025') || club.contestantClubName}
								</Link>
							</TableCell>
							<TableCell nowrap>{club.matchesPlayed}</TableCell>
							<TableCell nowrap>
								{club.matchesWon} / {club.matchesDrawn} / {club.matchesLost}
							</TableCell>
							<TableCell nowrap>{club.points}</TableCell>
							<TableCell nowrap>
								{club.goalsFor} / {club.goalsAgainst}
							</TableCell>
							<TableCell nowrap>{club.goalDifference}</TableCell>
							<TableCell className="hidden md:table-cell" nowrap>
								{resultSplitter(club.lastSix)}
							</TableCell>
						</tr>
					))}
				</TableBody>
			</Table>

			{legend && (
				<div className="block md:flex flex-row justify-between my-8 ">
					<div className="flex flex-row items-start">
						<div className="flex flex-row justify-center items-center mr-4">
							<div className="legend-blue-lg mr-2" />
							<span className={invertedHeaderColors ? 'text-white' : 'text-black-500'}>
								Promotie Eredivisie
							</span>
						</div>
						<div className="flex flex-row justify-center items-center mr-4">
							<div className="legend-yellow-lg mr-2" />
							<span className={invertedHeaderColors ? 'text-white' : 'text-black-500'}>
								Keuken Kampioen Play-Offs
							</span>
						</div>
					</div>
				</div>
			)}

			{displayPeriodTitle && (
				<div className="flex flex-row justify-start lg:my-8 ">
					<div className="my-6 mx-3 md:m-1 flex gap-4">
						<p className={invertedHeaderColors ? 'text-white text-sm' : 'text-black-500'}>
							+ Periodekampioen
						</p>
						<p className={invertedHeaderColors ? 'text-white text-sm' : 'text-black-500'}>
							* 27 punten in mindering
						</p>
					</div>
				</div>
			)}

			{showCorornaLegend && (
				<div className="block md:flex flex-row justify-between my-8 ">
					<div className="my-6 mx-3 md:m-1 flex items-center">
						* Door het annuleren van de competitie in verband met Covid werd er geen kampioen aangewezen en
						werden er geen play-offs gespeeld.
					</div>
				</div>
			)}
		</div>
	);
}
